import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from 'react-csv';
import api from '../../../services/API';
import { formatarMoeda } from '../../../utils/format';

const { SearchBar } = Search;

const ExportCSV = ({ data }) => {
  const headers = [
    { label: 'ID Revenda', key: 'ID Revenda' },
    { label: 'Nome Fantasia', key: 'nomeFantasia' },
    { label: 'ID Vendedor', key: 'ID Vendedor' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data Realização', key: 'realizadoEm' },
    { label: 'Código Item', key: 'codigo' },
    { label: 'ID Produto', key: 'idProduto' },
    { label: 'Quantidade', key: 'qtde' },
    { label: 'Valor Pontos', key: 'valorPontos' },
    { label: 'Pontos Unitários', key: 'Pontos Unitários' },
    { label: 'ID Produto (Tabela)', key: 'idProduto da tabela Produto' },
    { label: 'Nome Produto', key: 'Nome Prod da Tabela Produtos' },
    { label: 'Valor Unitário', key: 'valorUnitario' },
    { label: 'R$ Total', key: 'R$ Total' },
    { label: 'ID Venda', key: 'ID_Venda' },
    { label: 'ID Item Venda', key: 'ID_Item_Venda' }
  ];

  const formattedData = data.map(item => ({
    ...item,
    qtde: Math.floor(item.qtde),
    valorPontos: Math.floor(item.valorPontos),
    'Pontos Unitários': Math.floor(item['Pontos Unitários']),
    valorUnitario: formatarMoeda(item.valorUnitario),
    'R$ Total': formatarMoeda(item['R$ Total']),
    cpf: item.cpf ? item.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : '',
    realizadoEm: moment(item.realizadoEm).format('DD/MM/YYYY')
  }));

  return (
    <CSVLink
      data={formattedData}
      headers={headers}
      filename={`SellOut_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`}
      separator=";"
      className="btn-primario-slim ml-2 d-flex align-items-center justify-content-center"
      style={{ width: '38px', height: '38px' }}
    >
      <i className="fa fa-file-download"></i>
    </CSVLink>
  );
};

export default function SellOut() {
  const [redes, setRedes] = useState([]);
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [filtros, setFiltros] = useState({
    redeId: '',
    dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
    dataFinal: moment().format('YYYY-MM-DD'),
    buscar: ''
  });
  const [ordenacao, setOrdenacao] = useState({
    campo: 'data',
    direcao: 'desc'
  });
  const [timeoutRef] = useState({ current: null });

  useEffect(() => {
    carregarRedes();
    buscarDados();
  }, []);

  async function carregarRedes() {
    try {
      const response = await api.get('/api/redes');
      if (response.data && response.data.redes) {
        setRedes(response.data.redes);
      } else {
        toast.error('Formato de resposta inválido ao carregar redes');
      }
    } catch (error) {
      console.error('Erro ao carregar redes:', error);
      toast.error('Erro ao carregar redes');
    }
  }

  async function buscarDados(pageNumber = page, pageSize = sizePerPage) {
    console.log('Iniciando busca de dados...');
    console.log('Estado atual:', { filtros, ordenacao, page, sizePerPage });
    setBuscando(true);
    try {
      const params = {
        ...filtros,
        pagina: pageNumber,
        limite: pageSize,
        ordenacao: JSON.stringify({
          campo: ordenacao.campo,
          direcao: ordenacao.direcao
        })
      };
      console.log('Parâmetros enviados:', params);
      const response = await api.get('/api/sellout', { params });
      console.log('Resposta completa da API:', response.data);
      console.log('Primeiro registro:', response.data.rows[0]);
      if (response.data) {
        console.log('Atualizando estados com os dados recebidos');
        console.log('Rows:', response.data.rows);
        console.log('Count:', response.data.count);
        setData(response.data.rows || []);
        setTotalSize(response.data.count || 0);
        setPage(pageNumber);
        setSizePerPage(pageSize);
        console.log('Estados atualizados com sucesso');
        console.log('Novo estado data:', response.data.rows);
      }
    } catch (error) {
      console.error('Erro detalhado:', error);
      toast.error('Erro ao carregar dados');
      setData([]);
      setTotalSize(0);
    } finally {
      setBuscando(false);
    }
  }

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    console.log('handleTableChange chamado:', { type, page, sizePerPage, sortField, sortOrder });
    if (type === 'sort') {
      const novaOrdenacao = {
        campo: sortField,
        direcao: sortOrder
      };
      console.log('Nova ordenação:', novaOrdenacao);
      setOrdenacao(novaOrdenacao);
      buscarDados(1, sizePerPage);
    } else if (type === 'pagination') {
      console.log('Mudança de página/tamanho:', { page, sizePerPage });
      buscarDados(page, sizePerPage);
    }
  };

  const handleFiltroChange = (campo, valor) => {
    console.log('handleFiltroChange chamado:', { campo, valor });
    setFiltros(prev => {
      const novosFiltros = {
        ...prev,
        [campo]: valor
      };
      console.log('Novos filtros:', novosFiltros);
      return novosFiltros;
    });
  };

  const handleSearch = (e) => {
    const valor = e?.target?.value || '';
    setFiltros(prev => ({
      ...prev,
      buscar: valor
    }));
    
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      buscarDados(1, sizePerPage);
    }, 500);
  };

  const colunas = [
    {
      dataField: 'ID Revenda',
      text: 'ID Revenda',
      sort: true
    },
    {
      dataField: 'nomeFantasia',
      text: 'Nome Fantasia',
      sort: true
    },
    {
      dataField: 'ID Vendedor',
      text: 'ID Vendedor',
      sort: true
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      sort: true,
      formatter: (cell) => cell ? cell.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : ''
    },
    {
      dataField: 'realizadoEm',
      text: 'Data Realização',
      sort: true,
      formatter: (cell) => moment(cell).format('DD/MM/YYYY')
    },
    {
      dataField: 'codigo',
      text: 'Código Item',
      sort: true
    },
    {
      dataField: 'idProduto',
      text: 'ID Produto',
      sort: true
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      sort: true,
      align: 'right',
      formatter: (cell) => Math.floor(cell)
    },
    {
      dataField: 'valorPontos',
      text: 'Valor Pontos',
      sort: true,
      align: 'right',
      formatter: (cell) => Math.floor(cell)
    },
    {
      dataField: 'Pontos Unitários',
      text: 'Pontos Unitários',
      sort: true,
      align: 'right',
      formatter: (cell) => Math.floor(cell)
    },
    {
      dataField: 'idProduto da tabela Produto',
      text: 'ID Produto (Tabela)',
      sort: true
    },
    {
      dataField: 'Nome Prod da Tabela Produtos',
      text: 'Nome Produto',
      sort: true
    },
    {
      dataField: 'valorUnitario',
      text: 'Valor Unitário',
      sort: true,
      align: 'right',
      formatter: (cell) => formatarMoeda(cell)
    },
    {
      dataField: 'R$ Total',
      text: 'R$ Total',
      sort: true,
      align: 'right',
      formatter: (cell) => formatarMoeda(cell)
    },
    {
      dataField: 'ID_Venda',
      text: 'ID Venda',
      sort: true
    },
    {
      dataField: 'ID_Item_Venda',
      text: 'ID Item Venda',
      sort: true
    }
  ];

  const defaultSorted = [{
    dataField: 'data',
    order: 'desc'
  }];

  const options = {
    page,
    sizePerPage,
    totalSize,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: 'Todos', value: totalSize }
    ],
    onPageChange: (page, sizePerPage) => {
      buscarDados(page, sizePerPage);
    },
    onSizePerPageChange: (sizePerPage, page) => {
      buscarDados(page, sizePerPage);
    },
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      )
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">SellOut</h4>
      <hr className="hr-loja mt-0 mb-4" />

      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => handleFiltroChange('redeId', e.target.value)}
            value={filtros.redeId}
          >
            <option value="">Todas</option>
            {Array.isArray(redes) && redes.map((rede) => (
              <option key={rede.id} value={rede.id}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={filtros.dataInicial}
            onChange={(e) => handleFiltroChange('dataInicial', e.target.value)}
            max="2999-12-31"
          />
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={filtros.dataFinal}
            onChange={(e) => handleFiltroChange('dataFinal', e.target.value)}
            max="2999-12-31"
          />
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={handleSearch}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>

      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={colunas}
          search
        >
          {(props) => {
            console.log('ToolkitProvider props:', props);
            console.log('Data sendo passada para a tabela:', data);
            return (
              <div>
                <div className="d-flex flex-row-reverse mb-3">
                  <ExportCSV data={data} />
                  <input
                    type="text"
                    placeholder="Buscar"
                    className="input-theme mr-2"
                    onChange={handleSearch}
                    value={filtros.buscar}
                  />
                </div>
                <BootstrapTable
                  remote
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  pagination={paginationFactory(options)}
                  onTableChange={handleTableChange}
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                />
              </div>
            );
          }}
        </ToolkitProvider>
      </div>
    </Container>
  );
} 